.footer {
    margin-top: auto;
    display: flex;
    align-items: flex-end;
    padding: 1.5rem 0;
    border-top: 2px solid rgba(0, 0, 0, .05);
    font-size: 12px;

    .copyright {
        color: rgba(0, 0, 0, .65);
        font-size: inherit;
        font-style: italic;
        line-height: inherit;
        margin-right: 1rem;
        font-weight: inherit;
    }

    nav:not(:last-child) {
        flex-grow: 1;
        margin-right: 1rem;
    }

    .subtle-link:not(:last-child) {
        margin-right: 1rem;
    }
}
