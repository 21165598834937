a,
button,
.text-link {
    color: $secondary;
    text-decoration: none;
    outline: none;
    box-shadow: none;
    border: none;

    &:visited,
    &:focus,
    &:hover {
        color: $secondary;
        text-decoration: none;
        outline: none;
        box-shadow: none;
        border: none;
    }

    &:not(.logo):not(.nav-link) {
        font-weight: inherit;
        position: relative;

        &.passive {
            &:after {opacity: 0;}

            &:hover:after {opacity: 1;}
        }

        &:after {
            content: '';
            position: absolute;
            background: $primary;
            height: 2px;
            width: 100%;
            bottom: 0;
            left: 0;
            opacity: .5;
            z-index: -1;
        }

        &:hover,
        &:focus {
            &:after {
                bottom: 0;
                opacity: 1;
                height: 4px;
            }
        }
    }
}

.subtle-link {
    font-size: inherit;
    font-weight: $bold;
    text-decoration: underline;
    opacity: 0.65;

    &:after {
        display: none;
    }
}
