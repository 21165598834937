// colors
$primary: #d9664b;
$secondary: #451d35;
$blue: #4285F4;
$gray: rgba(0, 0, 0, .64);

// typography
$light: 300;
$regular: 400;
$bold: 500;

// fonts
$roboto: 'Roboto', sans-serif;

// sizing
$micro: 8px;
$tiny: 16px;
$small: 24px;
$medium: 40px;
$large: 64px;
$xl: 104px;
$xxl: 270px;

// responsive breakpoints
$mobile: 480px;
$tabletMax: 768px;
$tablet: 768px;
